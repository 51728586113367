module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackPageViews":true,"events":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"themihel.me | Mischa Helfenstein","short_name":"themihel.me","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","include_favicon":false,"icon":"static/maskable/icon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"static/maskable/icon_x48.png","sizes":"48x48","type":"image/png"},{"src":"static/maskable/icon_x72.png","sizes":"72x72","type":"image/png"},{"src":"static/maskable/icon_x96.png","sizes":"96x96","type":"image/png"},{"src":"static/maskable/icon_x128.png","sizes":"128x128","type":"image/png"},{"src":"static/maskable/icon_x192.png","sizes":"192x192","type":"image/png"},{"src":"static/maskable/icon_x384.png","sizes":"384x384","type":"image/png"},{"src":"static/maskable/icon_x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"7be83b00d0624d8d830bfeafa7da2dc6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
